import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

import { FadeUp } from '@happymoneyinc/move'

import Container from '../Container'
import Display from '../Display'
import Headline from '../Headline'
import IconBobbing from '../IconBobbing'
import Arrow from '../svg/Arrow'
import Dollar from '../svg/Dollar'
import Smile from '../svg/Smile'
import HeroCTA from '../HeroCTA'

const Hero = (props) => {
  const backgroundObjects = [
    {
      icon: <Smile color="#BDEDFF" />,
      animationDuration: 6.5,
      type: 'smile'
    },
    {
      icon: <Dollar color="#BDEDFF" />,
      animationDuration: 6.5,
      type: 'dollar'
    },
    {
      icon: <Arrow color="#BDEDFF" />,
      animationDuration: 6.5,
      type: 'arrow'
    },
  ]

  const {
    data: {
      hero_headline: headline,
      hero_subtext: subtext,
      hero_button_text: buttonText,
      hero_button_href: buttonHref,
      hero_button_tip: buttonTip
    }
  } = props

  return (
    <Wrapper>
      <TextContent>
        <Title testID={'hero-title'}>{headline}</Title>
        <Subtext testID={'hero-subtext'}>{subtext}</Subtext>
        <HeroCTA
          buttonContent={buttonText}
          buttonHref={buttonHref}
          buttonTip={buttonTip}
        />
      </TextContent>
      <Overlay>
        <Fade>
          {backgroundObjects &&
            backgroundObjects.map((item, index) => (
              <IconContainer
                key={`hero-icon-${ index.toString() }`}
                type={item.type}
              >
                <IconBobbing
                  duration={item.animationDuration}
                  movementX={{
                    min: -20,
                    max: 40
                  }}
                  movementY={{
                    min: 1,
                    max: 20
                  }}
                >
                  {item.icon}
                </IconBobbing>
              </IconContainer>
            ))}
        </Fade>
      </Overlay>
    </Wrapper>
  )
}

const SmileStyle = css`
  top: 0.5vw;
  left: 30vw;
  width: 49vw;
  transform: rotate(15deg);

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    left: 695px;
    width: 390px;
    max-width: 390px;
    transform: rotate(7deg);
  }
`

const DollarStyle = css`
  top: 32vw;
  left: 62vw;
  width: 35vw;
  transform: rotate(30deg);

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    top: 196px;
    left: 1005px;
    width: 280px;
    max-width: 280px;
  }
`

const ArrowStyle = css`
  top: 90vw;
  left: 45vw;
  width: 51vw;
  transform: rotate(30deg);

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    top: 420px;
    left: 575px;
    width: 400px;
    max-width: 400px;
  }
`

const IconContainer = styled.div`
  position: absolute;

  ${ ({ type }) => {
    if (type === 'smile') {
      return SmileStyle
    }
    if (type === 'dollar') {
      return DollarStyle
    }
    if (type === 'arrow') {
      return ArrowStyle
    }
  } }
`

const Wrapper = styled(Container)`
  overflow: hidden;
  background-color: ${ ({ theme }) => theme.colors.cream };

  > div {
    position: relative;
    padding-top: 80px;
  }
`
const TextContent = styled.div`
  position: relative;
  flex-direction: column;
  z-index: 1;
  display: flex;
  max-width: 566px;
`

const Title = styled(Display)`
  z-index: 2;
  margin: 0;

  @media screen and (max-width: ${ (props) => props.theme.breakpoints.xs1 }) {
    max-width: 250px;
    font-size: ${ ({ theme }) => `${ theme.display.medium.fontSizeMin }px` };
  }
`

const Subtext = styled(Headline)`
  z-index: 2;
  max-width: 437px;
  margin: 32px 0 72px;

  @media screen and (max-width: ${ (props) => props.theme.breakpoints.xs1 }) {
    max-width: 205px;
    margin: 24px 0 60px;
    font-size: ${ ({ theme }) => `${ theme.headline.medium.fontSizeMin }px` };
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  left: 0;
`

const Fade = styled(FadeUp)`
  transform: translate3d(0, 0, 0);
`

Hero.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
}

export default Hero
