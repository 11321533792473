import PropTypes from 'prop-types'

const Dollar = (props) => {
  const { color, className, style } = props

  return (
    <svg viewBox="0 0 289 512" fill="none" style={style} className={className}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M171.15 0H122.75V57.7477C61.2257 64.7748 21.0544 110.512 21.0544 161.852C21.0544 195.885 36.167 219.571 58.2458 235.798C77.18 249.713 101.234 258.106 122.75 264.874L122.75 402.337C86.1582 392.188 57.7463 367.273 44.3425 338.349L43.9137 337.424L0 357.774L0.428837 358.699C21.6644 404.523 66.9066 440.903 122.75 452.055V512H171.15V455.159C199.993 452.983 227.175 443.281 248.374 426.321C276.627 403.719 292.891 369.116 287.861 327.536L287.854 327.477L287.853 327.472L287.846 327.414C282.86 287.883 259.686 264.945 231.202 250.34C213.565 241.297 192.156 234.677 171.15 228.614L171.15 109.035C182.558 112.002 192.577 116.623 201.25 122.638C217.181 133.687 230.031 150.453 238.402 173.756L238.747 174.716L284.297 158.353L283.952 157.393C272.646 125.918 254.119 100.404 228.833 82.8668C211.788 71.0455 192.342 63.3128 171.15 59.5352V0ZM69.4543 161.852C69.4543 139.037 86.8219 112.822 122.75 106.591L122.75 213.908C108.024 208.77 96.0197 203.494 86.9084 196.798C81.3622 192.722 77.0152 188.234 74.0468 182.671C71.08 177.111 69.4543 170.409 69.4543 161.852ZM171.15 406.571L171.15 279.093C185.589 283.448 198.148 287.783 209.118 293.408C218.365 298.15 225.408 303.292 230.451 309.652C235.484 315.998 238.575 323.62 239.819 333.407C242.745 357.745 233.748 376.04 218.139 388.527C206.299 397.999 189.983 404.547 171.15 406.571Z"
        fill={color || '#242220'}
      />
    </svg>
  )
}

Dollar.defaultProps = {
  style: {},
  color: '',
  className: ''
}

Dollar.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  color: PropTypes.string,
  className: PropTypes.string
}

export default Dollar
