import PropTypes from 'prop-types'
import styled from 'styled-components'

const Container = (props) => {
  const { children, center, className, display, maxWidth } = props

  return (
    <ContainerBase className={className}>
      <Inner center={center} maxWidth={maxWidth} $display={display}>
        {children}
      </Inner>
    </ContainerBase>
  )
}

const ContainerBase = styled.section`
  position: relative;
`

const Inner = styled.div`
  align-items: ${ ({ center }) => (center ? 'center' : null) };
  flex-direction: column;
  display: ${ ({ $display }) => $display };
  padding: 80px 25px;
  margin: 0 auto;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    width: 100%;
    max-width: ${ ({ maxWidth }) => `${ maxWidth }` };
    padding: 120px 50px;
  }
`

Container.defaultProps = {
  className: '',
  center: false,
  display: 'flex',
  maxWidth: '1220px'
}

Container.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node]).isRequired,
  className: PropTypes.string,
  center: PropTypes.bool,
  display: PropTypes.string,
  maxWidth: PropTypes.string
}

export default Container
