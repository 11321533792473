import { fluidRange } from 'polished'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Quote = (props) => {
  const { children, className, size, testID } = props

  return (
    <QuoteTag
      className={className}
      data-testid={testID}
      size={size}
    >
      {children}
    </QuoteTag>
  )
}

const QuoteTag = styled.span`
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProLight }` };
  font-feature-settings: 'ss01' on, 'ss09' on, 'ss10' on;
  font-weight: 400;
  line-height: ${ ({ theme, size }) => theme.quote[size].lineHeight };
  letter-spacing: ${ ({ theme, size }) => `${ theme.quote[size].letterSpacing }em` };

  ${ ({ theme, size }) =>
    fluidRange(
      {
        prop: 'font-size',
        fromSize: `${ theme.quote[size].fontSizeMin }px`,
        toSize: `${ theme.quote[size].fontSizeMax }px`
      },
      `${ theme.breakpoints.xs }`,
      `${ theme.breakpoints.xl }`
    ) }
`

Quote.defaultProps = {
  children: '',
  className: '',
  size: 'large',
  testID: 'quote'
}

Quote.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.element, PropTypes.node]),
  className: PropTypes.string,
  size: PropTypes.oneOf(['large', 'medium', 'small']),
  testID: PropTypes.string
}

export default Quote
