import PropTypes from 'prop-types'
import styled from 'styled-components'

const IconBobbing = (props) => {
  const { children, duration, movementX, movementY } = props

  return (
    <Wrapper
      duration={duration}
      movementX={movementX.min}
      movementY={movementY.max}
    >
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  animation-duration: ${ ({ duration }) => duration }s;
  animation-iteration-count: infinite;
  animation-name: floating;
  animation-timing-function: ease-in-out;

  @keyframes floating {
    0% {
      transform: translate3d(${ ({ movementX }) => movementX }px, 0, 0);
    }

    65% {
      transform:
      translate3d(
        ${ ({ movementX }) => movementX + 5 }px,
        ${ ({ movementY }) => movementY }px,
        0
      );
    }

  100% {
    transform: translate3d(${ ({ movementX }) => movementX }px, 0, 0);
  }
}
`

IconBobbing.propTypes = {
  children: PropTypes.node.isRequired,
  duration: PropTypes.number.isRequired,
  movementX: PropTypes.objectOf(PropTypes.any).isRequired,
  movementY: PropTypes.objectOf(PropTypes.any).isRequired
}

export default IconBobbing
