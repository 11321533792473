import Image from 'next/image'
import { RichText } from 'prismic-reactjs'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

import { FadeUp } from '@happymoneyinc/move'

import Button from '../../components/Button'
import Container from '../../components/Container'
import Quote from '../../components/Quote'
import Headline from '../Headline'

const MemberStories = (props) => {
  const {
    data: {
      member_stories_button_href: buttonHref,
      member_stories_button_text: buttonText,
      member_stories_eyebrow: eyebrow,
      member_stories_image: image,
      member_stories_name: name,
      member_stories_quote: quote,
      member_stories_type: type,
    },
    theme,
  } = props

  return (
    <Wrapper>
      <FadeUp>
        <Box>
          <BoxInner
            padding={{
              mobile: '32px 32px 50px',
              desktop: '55px',
            }}
            color={theme.colors.cream}
          >
            <StyledQuote testID="member-stories-quote" size="small">
              {RichText.asText(quote)}
            </StyledQuote>

            <MemberInfo>
              <Name data-testid="member-stories-name">{name}</Name>
              <Type size="medium" testID="member-stories-type">{type}</Type>
            </MemberInfo>
          </BoxInner>

          <BoxInner>
            <Image
              alt={image.alt}
              data-testid="member-stories-image"
              height={760}
              layout="responsive"
              src={image.url}
              width={560}
            />
          </BoxInner>
        </Box>

        <Eyebrow testID="member-stories-eyebrow">{eyebrow}</Eyebrow>

        <StyledButton testID="member-stories-button" buttonContent={buttonText} buttonHref={buttonHref} color={'white'} />
      </FadeUp>
    </Wrapper>
  )
}

const StyledButton = styled(Button)`
  display: inline-block;
  width: 100%;
  margin: 0 auto;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    width: auto;
  }
`

const Eyebrow = styled(Headline)`
  margin: 80px 0 32px;

  text-align: center;
`

const Name = styled.p`
  margin: 0;
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProMedium }` };
  font-feature-settings: 'ss01' on;
  font-size: 18px;
  font-weight: bold;
  line-height: 100%;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    font-size: 32px;
  }
`

const Type = styled(Headline)`
  margin-bottom: 0;

`

const StyledQuote = styled(Quote)`
  margin-top: 0;
  margin-bottom: 30px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    margin-bottom: 0;
  }
`

const Box = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  max-width: 1118px;
  overflow: hidden;
  border: 1px solid ${ ({ theme }) => theme.colors.black };
  border-radius: 10px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    flex-direction: row;
  }
`

const BoxInner = styled.div`
  position: relative;
  flex-basis: 100%;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  padding: ${ ({ padding }) => (padding ? padding.mobile : null) };
  text-align: left;
  background-color: ${ ({ color }) => color };

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    flex-basis: 50%;
    padding: ${ ({ padding }) => (padding ? padding.desktop : null) };
  }
`

const MemberInfo = styled.div`
  flex-direction: column;
  display: flex;
`

const Wrapper = styled(Container)`
  align-items: center;
  display: flex;
  text-align: center;
  background-color: ${ ({ theme }) => theme.colors.azure[3] };
`

MemberStories.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
  theme: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default withTheme(MemberStories)
