import Image from 'next/image'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Headline from '../Headline'

const Benefit = (props) => {
  const {
    testID,
    benefit: {
      benefit_image: {
        dimensions,
        url,
        alt
      },
      benefit_text: subtext
    }
  } = props

  return (
    <Wrapper>
      <ImageWrapper>
        <Image src={url} width={dimensions.width} height={dimensions.height} alt={alt} />
      </ImageWrapper>
      <Headline as="p" size="medium" testID={testID}>{subtext}</Headline>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 300px;
`

const ImageWrapper = styled.div`
  width: 160px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    width: auto;
  }
`

Benefit.defaultProps = {
  testID: null
}

Benefit.propTypes = {
  testID: PropTypes.string,
  benefit: PropTypes.objectOf(PropTypes.any).isRequired,
}

export default Benefit
