import PropTypes from 'prop-types'
import styled from 'styled-components'

import { headlineLargeStyles } from '../utils/cmsStyles'

import Button from './Button'
import Display from './Display'
import Headline from './Headline'
import Text from './Text'

const CTA = (props) => {
  const {
    headline,
    headlineType,
    subtext,
    buttonContent,
    buttonHref,
    buttonTip,
    buttonColor,
    align,
    buttonMobileWidth,
    testID,
    className,
    handleOnClick,
  } = props

  return (
    <Wrapper align={align} className={className}>
      {headline && <StyledDisplay size="small" headlineType={headlineType} testID={testID ? `${ testID }-headline` : null} element="h2">
        {headline}
      </StyledDisplay>}
      {subtext && (
        <StyledHeadline size="medium" testID={testID ? `${ testID }-subtext` : null}>
          {subtext}
        </StyledHeadline>
      )}
      <StyledButton
        testID={testID ? `${ testID }-button` : null}
        buttonContent={buttonContent}
        buttonHref={buttonHref}
        color={buttonColor}
        buttonMobileWidth={buttonMobileWidth}
        onClick={handleOnClick}
      />
      {buttonTip && <ButtonTip size="small">{buttonTip}</ButtonTip>}
    </Wrapper>
  )
}

const ButtonTip = styled(Text)`
  margin: 24px 0 0;

  @media screen and (max-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
    max-width: 200px;
  }
`

const StyledButton = styled(Button)`
  width: auto;
  margin-top: 32px;

  @media screen and (max-width: ${ (props) => props.theme.breakpoints.sm }) {
    width: 100%;
    padding: 20px;
  }

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    margin-top: 48px;
  }
`

const StyledDisplay = styled(Display)`
  margin: 0;

  ${ ({ headlineType }) => headlineType === 'headline' &&
    headlineLargeStyles
}
`

const StyledHeadline = styled(Headline)`
  width: 76%;
  margin: 20px 0 0;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
    width: 100%;
  }
`

const Wrapper = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  width: 100%;
  text-align: center;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    align-items: ${ ({ align }) => align };
  }
`

CTA.defaultProps = {
  headline: '',
  headlineType: 'display',
  buttonContent: 'Check my rate',
  buttonColor: 'flamingo',
  buttonHref: '',
  buttonTip: '',
  align: 'flex-start',
  buttonMobileWidth: 'auto',
  testID: '',
  subtext: '',
  className: '',
  handleOnClick: null,
}

CTA.propTypes = {
  headline: PropTypes.string,
  headlineType: PropTypes.string,
  subtext: PropTypes.string,
  buttonContent: PropTypes.string,
  buttonColor: PropTypes.string,
  buttonHref: PropTypes.string,
  buttonTip: PropTypes.string,
  align: PropTypes.string,
  buttonMobileWidth: PropTypes.string,
  testID: PropTypes.string,
  className: PropTypes.string,
  handleOnClick: PropTypes.func,
}

export default CTA
