import Image from 'next/image'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { FadeUp } from '@happymoneyinc/move'

import Container from './Container'

const QuoteDoubleBorder = ({ data }) => {
  const {
    quote: {
      url: desktopImageUrl,
      dimensions,
      alt: desktopAlt,
      mobile: {
        url: mobileImageUrl,
        dimensions: mobileDimensions,
        alt: mobileAlt
      },
    },
    logo: {
      url: logoImageUrl,
      alt: logoAlt,
      dimensions: logoDimensions
    },
    bgColor,
    outerBoxColor,
    innerBoxColor
  } = data

  return (
    <Wrapper center bgColor={bgColor}>
      <FadeUp>
        <OuterBox bgColor={outerBoxColor}>
          <InnerBox bgColor={innerBoxColor}>
            <DesktopImage><Image layout="responsive" priority src={desktopImageUrl} width={dimensions.width} height={dimensions.height} alt={desktopAlt} /></DesktopImage>
            <MobileImage><Image layout="responsive" priority src={mobileImageUrl} width={mobileDimensions.width} height={mobileDimensions.height} alt={mobileAlt} /></MobileImage>
            <Logo maxWidth={logoDimensions.width}><Image layout="responsive" src={logoImageUrl} width={logoDimensions.width} height={logoDimensions.height} alt={logoAlt} /></Logo>
          </InnerBox>
        </OuterBox>
      </FadeUp>
    </Wrapper>

  )
}

const Wrapper = styled(Container)`
  background-color: ${ ({ theme, bgColor }) => bgColor || theme.colors.ultraviolet[3] };

  > div {
    padding: 60px 25px;

    @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
      padding: 80px 50px;
    }
  }

  .detect-wrapper {
    width: 100%;

    > div {
      align-items: center;
      flex-direction: column;
      display: flex;
    }
  }
`

const OuterBox = styled.div`
  width: fill-available;
  max-width: 350px;
  background-color: ${ ({ theme, bgColor }) => bgColor || theme.colors.chartreuse[3] };
  border: 0.75px solid ${ ({ theme }) => theme.colors.black };
  border-radius: 30px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
    max-width: initial;
    border: 1px solid ${ ({ theme }) => theme.colors.black };
    border-radius: 40px;
  }
`

const InnerBox = styled.div`
  padding: 55px 30px;
  margin: 15px;
  text-align: center;
  background-color: ${ ({ theme, bgColor }) => bgColor || theme.colors.azure[3] };
  border: 0.75px solid ${ ({ theme }) => theme.colors.black };
  border-radius: 15px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
    margin: 20px;
    border: 1px solid ${ ({ theme }) => theme.colors.black };
    border-radius: 20px;
  }

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    padding: 80px 100px 60px;
  }
`

const DesktopImage = styled.div`
  @media screen and (max-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
    display: none;
  }
`

const MobileImage = styled.div`
  display: none;

  @media screen and (max-width: ${ ({ theme }) => theme.breakpoints.xs1 }) {
    display: block;
  }
`

const Logo = styled.div`
  width: 120px;
  max-width: 155px;
  margin: 50px auto 0;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    width: initial;
    max-width: ${ ({ maxWidth }) => maxWidth }px;
    margin: 60px auto 0;
  }
`

QuoteDoubleBorder.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired
}

export default QuoteDoubleBorder
