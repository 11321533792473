import Image from 'next/image'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import DOMPurify from 'isomorphic-dompurify'

import { FadeUp } from '@happymoneyinc/move'

import Button from '../Button'
import Container from '../Container'
import Display from '../Display'
import Headline from '../Headline'
import BrandedQuote from '../BrandedQuote'
import Text from '../Text'
import { headlineMediumStyles } from '../../utils/cmsStyles'

const PayoffLoan = (props) => {
  const {
    data: {
      payoff_loan_headline: headline,
      payoff_loan_subtext: subtext,
      payoff_loan_button_text: buttonText,
      payoff_loan_button_href: buttonHref,
      payoff_loan_button_tip: buttonTip,
      payoff_loan_quote_text: quoteText,
      payoff_loan_quote_logo: quoteLogo,
      payoff_loan_quote_date: quoteDate,
      payoff_loan_terms: terms,
      payoff_loan_badge: badge
    },
  } = props

  return (
    <Wrapper>
      <FadeUp>
        <BgIcon>
          <Image src={'/wings-stars-bg.svg'} width={1171} height={347} alt="wing stars background" />
        </BgIcon>
        <MobileBgIcon>
          <Image src={'/wings-stars-mobile-left.svg'} width={70} height={217} alt="wing stars mobile background left" />
          <Image src={'/wings-stars-mobile-right.svg'} width={85} height={207} alt="wing stars mobile background right" />
        </MobileBgIcon>

        <TextContent>
          <StyledPayOffBadge testID={'payoff-loan-subtitle'}>
            <Image
              alt={badge?.alt || 'payoff-loan-subtitle'}
              height={badge?.dimensions?.height}
              src={badge?.url}
              width={badge?.dimensions?.width}
              layout="responsive"
              priority
              quality={100}
            />
          </StyledPayOffBadge>
          <Title size="small" testID={'payoff-loan-title'} element="h2">{headline}</Title>
          <Subtext dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(subtext) }} data-testid="payoff-loan-subtext" />
        </TextContent>

        <CTABlock>
          <StyledButton
            buttonContent={buttonText}
            buttonHref={buttonHref}
            color={'white'}
            testID={'payoff-loan-button'}
          />
          <CTASubtext size="small" testID={'payoff-loan-button-tip'}>{buttonTip}</CTASubtext>
        </CTABlock>

        <BrandedQuote
          quoteText={quoteText}
          quoteLogo={quoteLogo}
          quoteDate={quoteDate}
          testID={'payoff-loan-quote'}
        />

        <TermsWrapper>
          {terms.map((item, index) => (
            <Term key={item.term_text}>
              <Image src={item.icon.url} width={item.icon.dimensions.width} height={item.icon.dimensions.height} alt={item.icon.alt} />
              <StyledTerm size="medium" testID={`term-${ index.toString() }`}>{item.term_text}</StyledTerm>
            </Term>
          ))}
        </TermsWrapper>
      </FadeUp>
    </Wrapper>
  )
}

const Wrapper = styled(Container)`
  align-items: center;
  background-color: ${ ({ theme }) => theme.colors.flamingo[3] };
`

const BgIcon = styled.div`
  position: absolute;
  right: 10px;
  left: 10px;
  display: none;
  text-align: center;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    display: block;
  }
`

const MobileBgIcon = styled.div`
  position: absolute;
  top: -20px;
  right: 0;
  left: 0;
  justify-content: space-between;
  display: flex;

   @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    display: none;
  }

  > span {
    &:nth-child(2) {
      right: -5px;
    }
  }
`

const TextContent = styled.div`
  position: relative;
  align-items: center;
  flex-direction: column;
  z-index: 2;
  display: flex;
  margin: 0 auto;
  margin-bottom: 72px;
  text-align: center;
`

const Title = styled(Display)`
  max-width: 80%;
  margin: 0 auto;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    max-width: 60%;
  }
`

const Subtext = styled.p`
  ${ headlineMediumStyles }

  margin-bottom: 0;

  @media screen and (max-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    max-width: 80%;
    margin-right: auto;
    margin-left: auto;
  }
`

const CTABlock = styled.div`
  margin: 0 auto;
  margin-bottom: 80px;
  text-align: center;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    margin-top: 20px;
    margin-bottom: 120px;
  }
`

const CTASubtext = styled(Text)`
  margin-top: 24px;
  margin-bottom: 0;

  @media screen and (max-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    max-width: 55%;
    min-width: 185px;
    margin-top: 18px;
    margin-right: auto;
    margin-left: auto;
  }
`

const TermsWrapper = styled.div`
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  gap: 72px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    flex-direction: row;
    gap: 45px;
  }
`

const Term = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  text-align: center;
  gap: 24px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    flex-direction: row;
    text-align: left;
    gap: 17px;
  }

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    gap: 32px;
  }
`

const StyledTerm = styled(Headline)`
  width: 180px;
  margin: 0;
  white-space: break-spaces;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    width: auto;
  }

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    width: 240px;
  }
`
const StyledButton = styled(Button)`
  width: auto;

  @media screen and (max-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    width: 100%;
    padding-top: 21px;
    padding-bottom: 21px;
  }
`

const StyledPayOffBadge = styled.p`
  width: 217px;
  height: 32px;
  margin-top: 0;
  margin-bottom: 32px;

  @media screen and (max-width: ${ (props) => props.theme.breakpoints.xs1 }) {
    width: 172px;
    height: 22px;
    margin-bottom: 35px;
  }
`

PayoffLoan.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default PayoffLoan
