import PropTypes from 'prop-types'
import dynamic from 'next/dynamic'
import styled from 'styled-components'

import { FadeUp } from '@happymoneyinc/move'
import ArrowDown from '../../public/benefits/pink-arrow-down.svg'

import Button from '../Button'
import Container from '../Container'
import Smile from '../svg/Smile'

import { headlineLargeStyles } from '../../utils/cmsStyles'

import Benefit from './Benefit'

const ContentRichText = dynamic(() => import('../ContentRichText'))

const Benefits = (props) => {
  const {
    data: {
      benefits: benefitIcons,
      benefits_button_text: buttonText,
      benefits_button_href: buttonHref,
      benefits_philosophy: philosophy,
    },
  } = props

  return (
    <Wrapper center>
      <FadeUp>
        <BenefitsWrapper>

          {benefitIcons.map((benefit, index) => (
            <Benefit benefit={benefit} testID={`benefit-${ index.toString() }`} key={benefit?.benefit_text} />
          ))}
        </BenefitsWrapper>

        <StyledArrowDown />

        <PhilosophyBlock>
          {philosophy.map((item, index) => {
            return (
              <Content key={`philosophy-${ index.toString() }`} data-testid={`philosophy-${ index }`}>
                <ContentRichText text={item.content} />
              </Content>
            )
          })}
        </PhilosophyBlock>

        <StyledSmile />
        <Button
          color={'white'}
          buttonContent={buttonText}
          buttonHref={buttonHref}
          testID={'benefit-button'}
        />
      </FadeUp>
    </Wrapper>
  )
}

const Wrapper = styled(Container)`
  background-color: ${ ({ theme }) => theme.colors.chartreuse[3] };

  .detect-wrapper {
    width: 100%;

    > div {
      align-items: center;
      flex-direction: column;
      display: flex;
    }
  }
`

const BenefitsWrapper = styled.div`
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  display: flex;
  width: 100%;
  margin: 20px 0 60px;
  text-align: center;
  gap: 120px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    flex-direction: row;
    margin-top: 0;
    gap: 0;
  }

  p {
    white-space: break-spaces;
  }
`

const PhilosophyBlock = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;
  margin: 72px auto;
  text-align: center;
  gap: 80px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    max-width: 666px;
    margin: 48px auto 120px;
    gap: 120px;
  }
`

const Content = styled.div`
  p {
    margin: 0;
   ${ headlineLargeStyles }
  }

  @media screen and (width <= 429px) {
    > p {
      display: inline;

      &:first-child {
        margin-right: 5px;
      }
    }
  }

`

const StyledArrowDown = styled(ArrowDown)`
  display: block;
  margin: 0 auto;
`

const StyledSmile = styled(Smile)`
  width: 50px;
  margin-bottom: 72px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    width: 72px;
    margin-bottom: 60px;
  }
`

Benefits.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]).isRequired,
}

export default Benefits
