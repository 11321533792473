import PropTypes from 'prop-types'
import styled from 'styled-components'

const PressMarqueeInner = (props) => {
  const { imageSrc } = props

  return <Marquee imageSrc={imageSrc} />
}

const Marquee = styled.div`
  position: relative;
  left: 50%;
  width: 100vw;
  height: 120px;
  /* stylelint-disable-next-line function-no-unknown */
  background-image: ${ ({ imageSrc }) => `url(${ imageSrc }) ` };
  background-repeat: repeat-x;
  background-size: 300%;
  transform: translateX(-50%);
  animation: marquee 30s infinite linear;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.sm }) {
    background-size: 200%;
  }

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    background-size: contain;
  }

  @keyframes marquee {
    0% {
      background-position: 0;
    }

    100% {
      background-position: -1190px;
    }
  }
`

PressMarqueeInner.propTypes = {
  imageSrc: PropTypes.string.isRequired,
}

export default PressMarqueeInner
