import PropTypes from 'prop-types'

const Arrow = (props) => {
  const { color, className, style } = props
  return (
    <svg viewBox="0 0 416 421" fill="none" style={style} className={className}>
      <path
        d="M207.753 0.130859L0 231.151L36.0855 263.602L183.488 99.6919V420.131H232.018V99.692L379.42 263.602L415.506 231.151L207.753 0.130859Z"
        fill={color || '#242220'}
      />
    </svg>
  )
}

Arrow.defaultProps = {
  style: {},
  color: '',
  className: ''
}

Arrow.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  color: PropTypes.string,
  className: PropTypes.string
}

export default Arrow
