import { fluidRange } from 'polished'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Display = (props) => {
  const { children, className, element, size, testID } = props

  return (
    <DisplayTag
      as={element}
      className={className}
      data-testid={testID}
      size={size}
    >
      {children}
    </DisplayTag>
  )
}

const DisplayTag = styled.h1`
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
  font-feature-settings: 'ss01' on, 'ss09' on, 'ss10' on, 'ss02' on;
  font-weight: 400;
  line-height: ${ ({ theme, size }) => theme.display[size].lineHeight };
  letter-spacing: ${ ({ theme, size }) => `${ theme.display[size].letterSpacing }em` };

  ${ ({ theme, size }) =>
    fluidRange(
      {
        prop: 'font-size',
        fromSize: `${ theme.display[size].fontSizeMin }px`,
        toSize: `${ theme.display[size].fontSizeMax }px`
      },
      `${ theme.breakpoints.xs }`,
      `${ theme.breakpoints.xl }`
    ) }
`

Display.defaultProps = {
  children: '',
  className: '',
  element: 'h1',
  size: 'large',
  testID: 'display'
}

Display.propTypes = {
  children: PropTypes.node,
  className: PropTypes.string,
  /** Determines the HTML element that will be used by styled-components using as={}  */
  element: PropTypes.oneOf(['h1', 'h2', 'h3']),
  size: PropTypes.oneOf(['large', 'medium', 'small', 'xsmall']),
  testID: PropTypes.string
}

export default Display
