import PropTypes from 'prop-types'
import styled from 'styled-components'

import { FadeUp } from '@happymoneyinc/move'

import Container from '../../components/Container'
import Display from '../../components/Display'
import Headline from '../../components/Headline'

const Impact = (props) => {
  const {
    data: {
      impact_1: impact1,
      impact_1_value: impact1Value,
      impact_2: impact2,
      impact_2_value: impact2Value,
    }
  } = props

  return (
    <Wrapper>
      <FadeUp>
        <Inner>
          <Box align="flex-start">
            <StyledDisplay testID={'impact-1'} element="h2">{impact1}</StyledDisplay>
            <StyledHeadline testID={'impact-value-1'}>{impact1Value}</StyledHeadline>
          </Box>

          <Box align="flex-end">
            <StyledDisplay testID={'impact-2'} element="h2">{impact2}</StyledDisplay>
            <StyledHeadline testID={'impact-value-2'}>{impact2Value}</StyledHeadline>
          </Box>
        </Inner>
      </FadeUp>
    </Wrapper>
  )
}

const Inner = styled.div`
  flex-direction: column;
  display: flex;
  width: 100%;
  max-width: 1120px;
  margin: 0 auto;
  background-color: ${ ({ theme }) => theme.colors.poppy[3] };

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    flex-direction: row;
    justify-content: space-between;
  }
`

const Box = styled.div`
  align-items: center;
  flex-direction: column;
  display: flex;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.md }) {
    align-items: flex-start;
    flex: ${ ({ align }) => (align === 'flex-start' ? '1 0 52%' : '1 0 48%') };
    justify-content: ${ ({ align }) => align };
    min-height: 420px;

    > p {
      margin: 0 0 60px;
    }
  }

  > p {
    margin: 0 0 80px;
  }

  &:last-child {
    > p {
      margin-bottom: 0;
    }
  }
`

const StyledDisplay = styled(Display)`
  margin: 0;

  @media screen and (max-width: ${ (props) => props.theme.breakpoints.sm }) {
    font-size: 51px;
  }
`

const StyledHeadline = styled(Headline)`
  @media screen and (max-width: ${ (props) => props.theme.breakpoints.sm }) {
    text-align: center;
  }
`

const Wrapper = styled(Container)`
  overflow: hidden;
  background-color: ${ ({ theme }) => theme.colors.poppy[3] };
`

Impact.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
}

export default Impact
