import PropTypes from 'prop-types'
import styled from 'styled-components'

import { FadeUp } from '@happymoneyinc/move'

import Container from '../Container'
import CTA from '../CTA'

const CTABlock2 = (props) => {
  const {
    data: {
      cta_2_button_href: cta2ButtonHref,
      cta_2_button_text: cta2ButtonText,
      cta_2_headline: cta2Headline,
      cta_2_subtext: cta2Subtext,
    }
  } = props

  return (
    <Wrapper>
      <FadeUp>
        <CTA
          align={'center'}
          buttonColor={'azure'}
          buttonContent={cta2ButtonText}
          buttonHref={cta2ButtonHref}
          headline={cta2Headline}
          subtext={cta2Subtext}
          testID={'cta-2'}
        />
      </FadeUp>
    </Wrapper>
  )
}

const Wrapper = styled(Container)`
  flex-direction: row;
  justify-content: center;
  text-align: center;
  background-color: ${ ({ theme }) => theme.colors.chartreuse[3] };
`

CTABlock2.propTypes = {
  data: PropTypes.objectOf(PropTypes.string).isRequired,
}

export default CTABlock2
