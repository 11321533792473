import PropTypes from 'prop-types'

import Hero from '../components/homepage/Hero'
import Benefits from '../components/homepage/Benefits'
import QuoteDoubleBorder from '../components/QuoteDoubleBorder'
import PressMarquee from '../components/PressMarquee'
import PayoffLoan from '../components/homepage/PayoffLoan'
import CTABlock2 from '../components/homepage/CTABlock2'
import MemberStories from '../components/homepage/MemberStories'
import Impact from '../components/homepage/Impact'

import { createClient } from '../utils/prismic'
import { getPageData, getComponentData } from '../utils/getPageData'
import { interpolate } from '../utils/interpolate'
import { REVALIDATE_TIME_INTERVAL } from '../data/constants'

const Home = (props) => {
  const { data } = props

  const {
    press_logos_headline: headline,
    press_logos: {
      url
    }
  } = getComponentData({
    data,
    key: 'press_logos'
  })

  const { forbes_quote: quote, forbes_logo: logo } = getComponentData({
    data,
    key: 'forbes'
  })

  return (
    <>
      <Hero data={getComponentData({
        data,
        key: 'hero'
      })}
      />
      <Benefits data={getComponentData({
        data,
        key: 'benefits'
      })}
      />
      <QuoteDoubleBorder data={{
        quote,
        logo
      }}
      />
      <PressMarquee headline={headline} imageSrc={url} />
      <PayoffLoan data={getComponentData({
        data,
        key: 'payoff_loan'
      })}
      />
      <MemberStories data={getComponentData({
        data,
        key: 'member_stories'
      })}
      />
      <Impact data={getComponentData({
        data,
        key: 'impact'
      })}
      />
      <CTABlock2 data={getComponentData({
        data,
        key: 'cta_2'
      })}
      />
    </>
  )
}

export async function getStaticProps({ preview = null, previewData = {} }) {
  const client = createClient({ previewData })
  const { data: { template_variables: templateVarArr } } = await client.getSingle('template-variables')

  if (preview) {
    const { data } = await client.getByUID('homepage', 'homepage')
    const parsedData = await interpolate(templateVarArr, data)
    // Allow preview globalsite data in preview mode
    const { data: globalSiteData } = await client.getByUID('global', 'global-site-data')
    const parsedGlobalSiteData = await interpolate(templateVarArr, globalSiteData)
    let realTimeData
    try {
      ({ data: realTimeData } = await client.getSingle('real-time-data'))
    } catch (error) {
      realTimeData = null
    }

    return {
      props: {
        preview,
        data: parsedData,
        realTimeData,
        globalSiteData: parsedGlobalSiteData
      },
      revalidate: 1,
    }
  }

  const data = await getPageData({ uid: 'homepage' })
  const parsedData = await interpolate(templateVarArr, data)

  return {
    props: {
      data: parsedData,
    },
    revalidate: REVALIDATE_TIME_INTERVAL,
  }
}

Home.defaultProps = {
  data: {},
}

Home.propTypes = {
  data: PropTypes.oneOfType([PropTypes.object]),
}

export default Home
