import { fluidRange } from 'polished'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Headline = (props) => {
  const { children, className, element, size, testID } = props

  return (
    <HeadlineTag
      as={element}
      className={className}
      data-testid={testID}
      size={size}
    >
      {children}
    </HeadlineTag>
  )
}

const HeadlineTag = styled.p`
  font-family: ${ ({ theme }) => `${ theme.fonts.MabryProRegular }` };
  font-weight: 400;
  line-height: ${ ({ theme, size }) => theme.headline[size].lineHeight };
  letter-spacing: ${ ({ theme, size }) => `${ theme.headline[size].letterSpacing }em` };

  ${ ({ theme, size }) =>
    fluidRange(
      {
        prop: 'font-size',
        fromSize: `${ theme.headline[size].fontSizeMin }px`,
        toSize: `${ theme.headline[size].fontSizeMax }px`
      },
      `${ theme.breakpoints.xs }`,
      `${ theme.breakpoints.xl }`
    ) }
`

Headline.defaultProps = {
  children: '',
  className: '',
  element: 'p',
  size: 'large',
  testID: 'headline'
}

Headline.propTypes = {
  children: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  className: PropTypes.string,
  element: PropTypes.oneOf(['h4', 'h5', 'h6', 'p']),
  size: PropTypes.oneOf(['xxlarge', 'xlarge', 'large', 'medium', 'small']),
  testID: PropTypes.string
}

export default Headline
