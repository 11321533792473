import PropTypes from 'prop-types'

const Smile = (props) => {
  const { color, className, style } = props

  return (
    <svg viewBox="0 0 404 404" fill="none" style={style} className={className}>
      <path
        d="M84.0995 205.622C84.0995 270.809 136.813 323.522 202 323.522C267.187 323.522 319.9 270.407 319.9 205.622V203.61H276.04V205.622C276.04 246.666 242.641 279.662 202 279.662C160.956 279.662 127.96 246.263 127.96 205.622V203.61H84.0995V205.622Z"
        fill={color || '#242220'}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M202 404C90.5379 404 0 313.462 0 202C0 90.5379 90.5379 0 202 0C313.462 0 404 90.5379 404 202C404 313.462 313.462 404 202 404ZM202 46.6772C116.291 46.6772 46.6773 116.291 46.6773 202C46.6773 287.709 116.291 357.323 202 357.323C287.709 357.323 357.323 287.709 357.323 202C357.323 116.291 288.112 46.6772 202 46.6772Z"
        fill={color || '#242220'}
      />
    </svg>

  )
}

Smile.defaultProps = {
  style: {},
  color: '',
  className: ''
}

Smile.propTypes = {
  style: PropTypes.objectOf(PropTypes.any),
  color: PropTypes.string,
  className: PropTypes.string
}

export default Smile
