import Link from 'next/link'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { FadeUp } from '@happymoneyinc/move'

import Container from './Container'
import Text from './Text'

import PressMarqueeInner from './PressMarqueeInner'

const PressMarquee = (props) => {
  const { headline, imageSrc, isStatic, className } = props

  return (
    <MarqueeContainer className={className}>
      <FadeUp>{headline && (<Eyebrow size="eyebrow" testID={'press-logos-eyebrow'}>{headline}</Eyebrow>)}</FadeUp>

      <FadeUp>
        <Link href="/press" passHref>
          <Anchor aria-label="link-to-press-page" data-testid={'press-logos-link'}>
            <PressMarqueeInner isStatic={isStatic} imageSrc={imageSrc} />
          </Anchor>
        </Link>
      </FadeUp>
    </MarqueeContainer>
  )
}

const MarqueeContainer = styled(Container)`
  overflow: hidden;
  background-color: ${ ({ theme }) => theme.colors.cream };
`

const Anchor = styled.a`
  ::after {
    content: none;
  }
`

const Eyebrow = styled(Text)`
  width: 60%;
  margin: 0 auto 48px;
  text-align: center;
  text-transform: uppercase;

  @media screen and (min-width: ${ (props) => props.theme.breakpoints.md }) {
    margin-bottom: 102px;
  }
`

PressMarquee.defaultProps = {
  headline: null,
  isStatic: false,
  className: ''
}

PressMarquee.propTypes = {
  headline: PropTypes.string,
  imageSrc: PropTypes.string.isRequired,
  isStatic: PropTypes.bool,
  className: PropTypes.string
}

export default PressMarquee
