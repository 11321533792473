import PropTypes from 'prop-types'
import styled from 'styled-components'

const BorderBox = (props) => {
  const { className, children, testID } = props

  return (
    <BoxContainer className={className} data-testid={testID} >
      {children}
    </BoxContainer>
  )
}

const BoxContainer = styled.div`
  box-sizing: border-box;
  width: inherit;
  padding: 60px 51px;
  text-align: center;
  background-color: ${ ({ theme }) => theme.colors.white };
  border: 1px solid ${ ({ theme }) => theme.colors.black };
  border-radius: 10px;

  @media screen and (min-width: ${ ({ theme }) => theme.breakpoints.sm }) {
    padding: 72px 118px;
  }
`
BorderBox.defaultProps = {
  className: '',
  testID: null
}

BorderBox.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
  testID: PropTypes.string,
}

export default BorderBox
